<template>
  <div class="content__body">
    <form @submit.prevent="onSendForm">
      <app-form-group label="Группы пользователей">
        <app-checkbox-list v-model="user_groups" :data="groups_options" />
      </app-form-group>
      <app-cells>
        <app-button ref="submit">Сохранить</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
export default {
  name: 'UserSettings',
  data() {
    return {
      user_groups: [],
      groups_options: []
    }
  },
  created() {
    this.$store.dispatch('admin/users/GET_GROUPS')
      .then(res => { this.groups_options = res.data })
    this.$store.dispatch('admin/users/GET_DATA', this.$route.params.id)
      .then(response => { this.user_groups = response.data.groups })
  },
  methods: {
    onSendForm() {
      this.$refs.submit.preload = true
      this.$store.dispatch('admin/users/PATCH_DATA', { data: { groups: this.user_groups }, id: this.$route.params.id })
        .then(() => {
          this.$refs.submit.preload = false
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Обновления успешно сохранены'
          })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
  }
}
</script>
